// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "19.5.0",
  "buildNumber": "9272",
  "gitSha": "496bf8b95e603c1f3980ae59042bc559eecbbbc0",
  "fullVersion": "19.5.0-9272",
  "formattedVersion": "19.5.0 (9272-496bf8b95e603c1f3980ae59042bc559eecbbbc0)",
  "copyrightYear": "2019"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/19.5.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/19.5.0/${stripLeadingPrefix(suffix, '#')}`
}
    
